import React, {useEffect,useState} from "react"
import Layout from "./layout"
import { graphql } from "gatsby"
import { Slide, Fade, Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'

let $
let cards;

if (typeof window !== `undefined`) {
  $ = window.$;
}
//<pre>{JSON.stringify(data,null,1)}</pre>
let slideStyle = {
  display: "block",
  height: "calc(100%)",
  // width: "94vw",
}

let slideContStyle = {
  // maxWidth: "94vh",
  // height: 0,
  // paddingBottom: "50%"
  // height: "80%"
}

const Fancy3DPageTurn = ({children}) => {
  return (
    <div style={{position: "relative"}}>
      <div id="flipbook" style={{
        boxShadow: "black 0px 0px 10px",
        // margin: '1em'
      }}>
        {children}
      </div>

    </div>
  )
}
const blindStyle = {
  listStyle: "none"
}
const Blinds = ({imgs}) => {
  return (
    <div id="blindify">
      <ul style={blindStyle}>
      {
        imgs.map((image,i) => <li key={i}>
        <img src={image.publicURL}/>
        </li>)
      }
      </ul>
    </div>
  )
}

const Hinge = ({imgs}) => {
  return (
    <div id="hinge">
      {imgs.map((img,i) => <div className="inner" key={i} style={{
        backgroundImage: `url(${img.publicURL})`
      }}></div> )}
    </div>
  )
}

const Cardsz = ({imgs}) => {
  console.log(imgs)
  return (
    <ul id="cards" className="baraja-container">
      {imgs.map((img,i) => <li className="inner" key={i} style={{
        backgroundImage: `url(${img.src})`
      }}></li> )}
    </ul>
  )
}

const Cards = ({imgs}) => {
  console.log(imgs)
  return (
    <ul id="cards" className="baraja-container">
      {imgs.map((image,i) => <li className="inner" key={i}>
      <GatsbyImage objectFit={"cover"} loading={"eager"} image={getImage(image)} key={i} alt=""/>
      </li> )}
    </ul>
  )
}

const transitions = {
  'fade': Fade,
  'slide': Slide,
  'zoom': Zoom,
  'fancypageturn': Fancy3DPageTurn,
  'blinds': Blinds,
  'hinge': Hinge,
  'cards': Cards
}



const jListener = ($) =>
  (e) => {
    if(!$("#flipbook").length)
      return;
    console.log("omg clicked")
    if(e.key==="ArrowRight")
      $("#flipbook").turn("next")
    if(e.key==="ArrowLeft")
      $("#flipbook").turn("previous")
  }
  const clickListener = ($) =>
    (e) => {
      if($(e.nativeEvent.target).closest(".slide-container").length){
        $(".arrow:eq(1)").click()
      }
      if(!$(e.nativeEvent.target).closest(".turn-page-wrapper").length)
        return;
      console.log("omg clicked")
      e.preventDefault()
      let even = parseInt($(e.nativeEvent.target).closest(".turn-page-wrapper").attr("page")) % 2 === 0
      if(typeof window !== "undefined")
        even = e.nativeEvent.pageX < window.innerWidth/2
      if(even)
         $("#flipbook").turn("previous")
      else
         $("#flipbook").turn("next")
    }

let onpress = jListener($)
let onclick = clickListener($)
var hingeSlider;
var hingeSliderIndex = 0;

export default function Slideshow({data}) {
  const post = data.markdownRemark
  const gif_based = post.frontmatter.images[0].childImageSharp === null
  const imageProps = gif_based ? (e => e.publicURL) : (e => e)
  const background_images = post.frontmatter.background_images ? post.frontmatter.background_images.map(e=>e.publicURL) : null
  const images = post.frontmatter.images.map(imageProps)
  const arrows = post.frontmatter.arrows ? post.frontmatter.arrows : null
  const transition = post.frontmatter.transition || "fade"
  const duration = post.frontmatter.duration || null
  const transitionDuration = post.frontmatter.transitionDuration || 1000
  const Container = transitions.hasOwnProperty(transition) ? transitions[transition] : Fade
  const ratio = post.frontmatter.aspect_ratio ? post.frontmatter.aspect_ratio : .75
  let [mountedTurn,setMountedTurn] = useState(false)
  let [slide,setSlide] = useState(0)
  console.log(transitionDuration)
  const zoomProperties = {
      indicators: false,
      scale: post.frontmatter.zoom_scale || 1.4
    }
  const slideClicked = e => {
    console.log(slide)
    setSlide(slide+1);
  }
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const horizontal = window.innerHeight-75 < window.innerWidth
      const isMobile = window.innerWidth < 480
      const maxBounds = {
        x: Math.floor(window.innerWidth*.9),
        y: Math.floor((window.innerHeight-75)*.7)
      }
      let size = {
        width: horizontal ? maxBounds.x*ratio : maxBounds.x,
        height: horizontal || isMobile ? maxBounds.y : maxBounds.x
      }
      if((transition === "fancypageturn") && !mountedTurn){
        $("#flipbook").turn({
          ...size,
          display: isMobile ? "single" : "double",
          autoCenter: true
        });

        document.removeEventListener("keydown", onpress)
        document.addEventListener("keydown", onpress, false);
        document.addEventListener("keydown", onpress, false);
        window.addEventListener("resize", () => $("#flipbook").turn("resize"), false);
        setMountedTurn(true)
        return () => {
          document.removeEventListener("keydown", onpress)

        }
      }
      if((transition === "blinds") && !mountedTurn){
        $(document).ready(function(){
            $('#blindify').blindify({
                numberOfBlinds: 30,
                animationSpeed: 1000,
                delayBetweenSlides: 600,
                color: "#ffffffff",
                width:window.innerWidth,
                height: window.innerHeight
            });
        });
      }
      if(window.hasOwnProperty("carddeckslideshow") && (transition === "hinge") && !mountedTurn){
        hingeSlider = new window.carddeckslideshow({
      		id: 'hinge',
      		autoplay: true,
      		cycles: 2,
      		persist: false
      	})
        $("#hinge").click(function(e){
          hingeSliderIndex++;
          console.log(hingeSliderIndex)
          e.preventDefault();
          e.stopPropagation()
          // if(hingeSliderIndex%2 === 0)
            hingeSlider.navigate("next",false)
        })
      }
      if((transition === "cards") && !mountedTurn){
        cards = $( '#cards' ).baraja();
        cards.fan( {
      		speed : 500,
      		easing : 'ease-out',
      		range : 100,
      		direction : 'right',
      		origin : { x : 50, y : 180 },
      		center : true,
      		translation : 0
      	} );
      }
    }
  },[]);
  let autoPlay = duration ? {
    duration: duration,
    arrows: false
  } : {
    autoplay: false,
    arrows: true,
    prevArrow: <div id="prev-arrow" className="arrow" style={{
      backgroundImage: arrows ? `url(${arrows[0].publicURL})`: ''
    }}></div>,
    nextArrow: <div id="next-arrow" className="arrow" style={{
      backgroundImage: arrows ? `url(${arrows[1].publicURL})` : ''
    }}></div>
  }
  let touchFunctions = {
    onTouchStart:onclick,
    onClick:onclick,
  }
  let content = (
    <div className={`slide-container on-slide-${slide}`} style={slideContStyle} onClick={slideClicked}>
      <Container {...zoomProperties} imgs={images} {...autoPlay} transitionDuration={transitionDuration} >
        {images.map( (image,i) => (
          gif_based ? <div className="each-slide" key={i} {...touchFunctions} style={{
            'backgroundImage': `url(${image})`,
            ...slideStyle
          }}></div> : <GatsbyImage alt="" objectFit={"contain"}  loading={"eager"} image={getImage(image)} className="each-slide" key={i} {...touchFunctions} style={{
            //'backgroundImage': `url(${image})`,
            ...slideStyle
          }} />
        ))}
      </Container>
    </div>
  )
  return <Layout data={data}>{content}</Layout>
}
// backgroundImages={background_images} took this out of export
