import Slideshow from "../components/slideshow"
import { graphql } from "gatsby"

export default Slideshow
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      html
      frontmatter {
        title
        tags
        display
        arrows {
          publicURL
        }
        background_color
        background_images {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        audio {
          publicURL
        }
        transition
        zoom_scale
        duration
        vangogh_object
        transitionDuration
        aspect_ratio
        images {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 1400
            )
          }
        }
      }
      fields {
        slug
        table
      }
    }
  }
`
